import type {MouseEvent, MouseEventHandler, ReactElement, ReactNode, Ref} from 'react';
import React from 'react';
import './button.scss';
import {IconSize} from '@Components/icon-v2/icon.types';
import {Icon} from '../icon-v2';

export interface ButtonProps {
  type?: Type;
  /**
   * ID to give to the button
   */
  id?: string;
  /**
   * Additional classes to give to the button
   */
  customClasses?: string;
  size?: Size;
  iconPlacement?: IconPlacement;
  href?: string;
  /**
   * The class that renders the icon
   */
  icon?: string;
  iconClassName?: string;
  /**
   * Additional classes to give to the text span too
   */
  textClasses?: string;
  /**
   * Text on button to display
   */
  text?: string;
  title?: string;
  /**
   * Text on button to below 500px width
   */
  shortText?: string;
  /**
   * Handler for when mouse is clicked
   */
  onClick?: MouseEventHandler | (() => Promise<any>);
  disabled?: boolean;
  hasSharpEdges?: boolean;
  /**
   * a key value pair of any attributes we want to give to the root component such as data-id etc
   */
  attributes?: Record<string, string>;
  children?: ReactNode;
  isLoading?: boolean;
  isFullWidth?: boolean;
  /**
   * classes that get applied to the loader
   */
  loaderClassName?: string;
  isActive?: boolean;
}

export enum Size {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum Type {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  SUCCESS = 'success',
  PREMIUM = 'premium',
  PREMIUM2 = 'premium2',
  PRIMARY_DARK = 'primarydark',
  DARK = 'dark',
  DARK_SECONDARY = 'darksecondary',
  GHOST = 'ghost',
  DASHED = 'dashed',
  DARK_GHOST = 'darkghost',
}

export enum IconPlacement {
  LEFT = '-icon-left',
  RIGHT = '-icon-right',
}

const HREF_DEFAULT = 'javascript:void(0);';

export const Button = React.forwardRef(
  (
    {
      type,
      href = HREF_DEFAULT,
      id = '',
      size = Size.MEDIUM,
      customClasses = '',
      icon = '',
      iconClassName = '',
      loaderClassName = '',
      textClasses = '',
      iconPlacement = IconPlacement.LEFT,
      disabled = false,
      hasSharpEdges = false,
      onClick = (): void => {},
      text = '',
      title = '',
      attributes = {},
      isLoading = false,
      isFullWidth = false,
      isActive = false,
      ...props
    }: ButtonProps,
    ref: Ref<any>
  ): ReactElement | null => {
    const hasUrl = href !== HREF_DEFAULT;
    const BtnTag = hasUrl ? 'a' : 'button';
    const classes = [
      'btn',
      size,
      type,
      type === Type.DASHED ? '-no-border' : '',
      iconPlacement,
      hasSharpEdges ? '-sharp-edges' : '',
      isLoading ? '-loading' : '',
      isFullWidth ? '-fullwidth' : '',
      customClasses,
      props.shortText !== undefined ? '-has-short-text' : '',
      text ? '' : '-no-text',
      isActive ? '-active': ''
    ];

    hasUrl ? (attributes.href = href) : null;

    const getIconSize = (): IconSize => {
      switch (size) {
        case Size.XSMALL:
          return IconSize.SIZE_ICON_16;
        case Size.SMALL:
          return IconSize.SIZE_ICON_16;
        case Size.MEDIUM:
          return IconSize.SIZE_ICON_20;
        case Size.LARGE:
          return IconSize.SIZE_ICON_24;
        default:
          return IconSize.SIZE_ICON_20;
      }
    };

    const onClickHandler = (e: MouseEvent): void => {
      if (isLoading) {
        return;
      }
      void onClick(e);
    };

    return (
      <BtnTag ref={ref} id={id} className={classes.join(' ')} disabled={disabled} onClick={onClickHandler} title={title} {...attributes}>
        <span className={`btn-text ${textClasses}`}>
          <span className={`-long ${props.shortText !== undefined ? '-has-short-text' : ''}`}>{text}</span>
          {props.shortText ? <span className="-short">{props.shortText}</span> : null}
        </span>
        {icon ? (
          <span className="btn-icon">
            <Icon icon={icon} className={iconClassName} size={getIconSize()} />
          </span>
        ) : null}
        <div className={`${loaderClassName} btn-loader`}>
          <progress className="icon-circular-loader" />
        </div>
        {props.children}
      </BtnTag>
    );
  }
);
