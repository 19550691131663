import {createSlice} from '@reduxjs/toolkit';
import {addNewBrand} from "../mystuff-brands/brands-thunk.js";

const initialState = {
    isMyStuffLoading: false,
    userTeamInformation: {}
};

const myStuffSlice = createSlice({
        name: 'myStuff',
        initialState,
        reducers: {
            showMyStuffLoadingIndicator: (state) => {
                state.isMyStuffLoading = true;
            },
            hideMyStuffLoadingIndicator: (state) => {
                state.isMyStuffLoading = false;
            },
            setTeamData: (state, action) => {
                state.userTeamInformation = action.payload;
            }
        }
    }
);

export const {showMyStuffLoadingIndicator, hideMyStuffLoadingIndicator} = myStuffSlice.actions;
export const myStuffReducer = myStuffSlice.reducer;

