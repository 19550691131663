import {
  deleteEmail,
  deleteMailingList,
  getEmailsByMailingListId,
  updateMailingListName,
} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience-thunk';
import type {ActionReducerMapBuilder} from '@reduxjs/toolkit/src/mapBuilders';
import type {NoInfer} from '@reduxjs/toolkit/src/tsHelpers';
import type {AudienceState, MailingList} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience-reducer';
import {
  addAudience,
  addAudienceIdsToMailingList,
  addMailingList,
  changeMailingListName,
  removeAudience,
  removeMailingList,
} from '@Components/email-marketing-campaign-audience/email-marketing-campaign-audience-reducer';
import {getEmailStatesEnumValues} from '@Libraries/email-marketing-campaigns-audience-library';

export const emailMarketingCampaignAudienceExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<AudienceState>>) => {
  builder.addCase(getEmailsByMailingListId.fulfilled, (state, {payload}) => {
    const audienceIds = [];
    for (let i = 0; i < payload.audiences.length; i++) {
      addAudience(state, payload.audiences[i]);
      audienceIds.push(payload.audiences[i].id);
    }

    if (!state.mailingLists[payload.idMailingList]) {
      const getEmailsByMailingListIdParams: MailingList = {
        emailIds: audienceIds,
        name: payload.name,
        totalEmailsCount: payload.count,
        filters: getEmailStatesEnumValues().reduce((obj, emailState) => {
          return {...obj, [emailState]: true};
        }, {}),
        searchTerm: '',
        isSpam: payload.isSpam,
        lastViewed: payload.lastViewed,
      };

      addMailingList(state, payload.idMailingList, getEmailsByMailingListIdParams);
    } else {
      addAudienceIdsToMailingList(state, payload.idMailingList, audienceIds);
    }
  });

  builder.addCase(deleteEmail.fulfilled, (state, {meta}) => {
    const {audience, idMailingList, index} = meta.arg;
    const idAudience = audience.id;

    removeAudience(state, idMailingList, idAudience, index);
  });

  builder.addCase(updateMailingListName.pending, (state, {meta}) => {
    const {idMailingList, newName} = meta.arg;
    changeMailingListName(state, idMailingList, newName);
  });

  builder.addCase(updateMailingListName.rejected, (state, {meta}) => {
    const {idMailingList, currentName} = meta.arg;
    changeMailingListName(state, idMailingList, currentName);
  });

  builder.addCase(deleteMailingList.fulfilled, (state, {meta}) => {
    const {idMailingList} = meta.arg;
    removeMailingList(state, idMailingList);
  });
};
