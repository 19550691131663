import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

interface StateProps {
  isBackgroundRemoved: boolean;
  showLoading: boolean;
}

const initialState: StateProps = {
  isBackgroundRemoved: false,
  showLoading: true,
};

export const cropperPanelSlice = createSlice({
  name: 'trimPanel',
  initialState,
  reducers: {
    setIsBackgroundRemoved(state, action: PayloadAction<boolean>) {
      state.isBackgroundRemoved = action.payload;
    },
    setShowLoading(state, action: PayloadAction<boolean>) {
      state.showLoading = action.payload;
    },
  },
});

export const {setIsBackgroundRemoved, setShowLoading} = cropperPanelSlice.actions;
export const copperPanelReducer = cropperPanelSlice.reducer;
