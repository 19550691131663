export enum GA4EventName {
  ADD_OWN_AUDIO = 'AddOwnAudio',
  ADD_SLIDESHOW = 'AddSlideshow',
  START_DRAWING = 'StartDrawing',
  SAVED_POSTER = 'SavePoster',
  GETTY_IMAGE_LIMIT_REACHED = 'GettyImageLimitReached',
  GETTY_VIDEO_LIMIT_REACHED = 'GettyVideoLimitReached',
  VIDEO_LIMIT_DIALOG = 'VideoLimitDialog',
  REMOVE_BACKGROUND = 'RemoveBackground',
  REMOVE_BACKGROUND_VIDEO = 'RemoveBackgroundVideo',
  SEARCH_BACKGROUND = 'SearchBackground',
  SEARCH_STORYBLOCKS_ILLUSTRATIONS = 'StoryblocksIllustrationsSearch',
  SEARCH_PIXABAY_ILLUSTRATIONS = 'PixabayIllustrationsSearch',
  ADDED_SLIDESHOW_ANIMATION = 'AddedSlideshowAnimation',
  ADDED_POSTER_ANIMATION = 'AddedPosterAnimation',
  CREATE_POSTER = 'CreatePoster',
  UPLOAD_FONT = 'UploadFont',
  DIGITAL_DISPLAY_PUBLISHED = 'DigitalDisplayPublished',
  EDITOR_ONBOARDING_VISITED = 'EditorOnboarding_Visited',
  EDITOR_ONBOARDING_SKIPPED = 'EditorOnboarding_Skipped',
  DOWNLOAD_CAMPAIGN_PDF = 'EmailPerformance_DownloadCampaign_PDF',
  DOWNLOAD_CAMPAIGN_CSV = 'EmailPerformance_DownloadCampaign_CSV',
  DOWNLOAD_MAILING_LIST_CSV = 'EmailPerformance_DownloadMailingList',
  ACCOUNT_ADDED = 'AccountAdded',
  ACCOUNT_DELETED = 'AccountDeleted',
  ACCOUNT_ADDED_MYSTUFF = 'AccountAdded_MyStuff',
  POST_PUBLISHED = 'PostPublished',
  POST_SCHEDULED = 'PostScheduled',
  PUBLISHING_INTRO_VIDEO = 'PublishingIntroVideo',
  PUBLISHING_ONBOARDING = 'PublishingOnboarding',
  MYSTUFF_SOCIALPOSTS_CREATEDESIGN = 'MyStuff_SocialPosts_CreateDesign',
  SOCIALWIZARD_ACCOUNTADDED = 'SocialWizard_AccountAdded',
  SOCIALWIZARD_NUMPLATFORMS = 'SocialWizard_NumPlatforms',
  SOCIALWIZARD_EDITDESIGN = 'SocialWizard_EditDesign',
  SOCIALWIZARD_RESIZEOPTIONS = 'SocialWizard_ResizeOptions',
  SOCIALWIZARD_AUTORESIZE = 'SocialWizard_AutoResize',
  MEDIA_MODAL_OPEN = 'MediaModal_Open',
  UPLOADS_MODAL_OPEN = 'UploadsModal_Open',
  EMAILMAKER_EMBEDVIDEO = 'EmailMaker_EmbedVideo',
  SOCIALWIZARD_AICAPTIONS_CLICKED = 'SocialWizard_AICaptions_Clicked',
  SOCIALWIZARD_AICAPTIONS_ADDED = 'SocialWizard_AICaptions_Added',
  SOCIALWIZARD_AICAPTIONS_FIRSTGEN = 'SocialWizard_AICaptions_FirstGen',
  SOCIALWIZARD_AICAPTIONS_MOREIDEAS = 'SocialWizard_AICaptions_MoreIdeas',
  SOCIALWIZARD_AICAPTIONS_STARTOVER = 'SocialWizard_AICaptions_StartOver',
  SOCIALWIZARD_AICAPTIONS_ONBOARDING_TRYNOW = 'SocialWizard_AICaptions_Onboarding_TryNow',
  SOCIALWIZARD_AICAPTIONS_ONBOARDING_LATER = 'SocialWizard_AICaptions_Onboarding_Later',
  SOCIALWIZARD_AICAPTIONS_REMINDER_VISIBLE_CLICK = 'SocialWizard_AICaptions_Clicked_Popover',
  SOCIALWIZARD_AICAPTIONS_RECENTS = 'SocialWizard_AICaptions_Recents',
  SOCIALWIZARD_AICAPTIONS_UPSELLING = 'SocialWizard_AICaptions_Upselling',
  SOCIALWIZARD_AICAPTIONS_LIMIT_REACHED = 'SocialWizard_AICaptions_DailyLimitHit',
  SOCIALWIZARD_UPLOADMEDIA_CLICKED = 'SocialWizard_UploadMedia_Clicked',
  SOCIALWIZARD_UPLOADMEDIA_CREATED = 'SocialWizard_UploadMedia_Created',
  TEXT_MENU_OPEN = 'TextMenu_Open',
  LAYOUT_MENU_OPEN = 'LayoutMenu_Open',
  SLIDESHOW_MENU_OPEN = 'SlideshowMenu_Open',
  BACKGROUND_MENU_OPEN = 'BackgroundMenu_Open',
  QR_CODE_ADDED = 'AddedQRCode',
  MORE_OPTIONS_OPEN = 'MoreOptions_Open',
  RECORD_MENU_OPEN = 'RecordMenu_Open',
  UPLOADSMODAL_RECORDED_MEDIA = 'UploadsModal_RecordedMedia',
  UPLOADSMODAL_RECORDED_MEDIA_ADDED = 'UploadsModal_RecordedMedia_Added',
  UPLOADSMODAL_UPLOAD_MEDIA = 'UploadsModal_UploadMedia',
  EMAILMAKER_UPLOAD_MEDIA = 'EmailMaker_UploadMedia',
  DESIGNER_PROFILE_CLICK = 'DesignerProfileClick_PosterBuilder',
  AI_IMAGE_MENU_OPEN = 'AIImageMenu_Open',
  AI_IMAGE_MENU_GENERATE = 'AIImageMenu_Generate',
  AI_IMAGE_TOTAL_GENERATIONS = 'AIImage_TotalGenerations',
  MEDIA_MODAL_AI_IMAGE_GENERATE_YOUR_OWN = 'MediaModal_AIImage_GenerateYourOwn',
  MEDIA_MODAL_AI_IMAGE_VIEW_ALL = 'MediaModal_AIImage_ViewAll',
  MEDIA_MODAL_AI_IMAGE_GENERATE = 'MediaModal_AIImage_Generate',
  CREATE_DESIGN_FROM_MEDIA_CLICKED = 'CreatePosterFromUpload_Clicked',
  CREATE_DESIGN_FROM_MEDIA_CREATED = 'CreatePosterFromUpload_Created',
  CONTENTPLANNER_ADD_EMAIL = 'ContentPlanner_AddEmail',
  CONTENTPLANNER_ADD_SOCIAL_POST = 'ContentPlanner_AddSocialPost',
  CONTENTPLANNER_ADD_CUSTOM_EVENT = 'ContentPlanner_AddCustomEvent',
  EMAILMAKER_SUBJECT_LINE_AI_WRITER_CLICKED = 'EmailMaker_SubjectLine_AIWriter_Clicked',
  EMAILMAKER_SUBJECT_LINE_AI_WRITER_GENERATED = 'EmailMaker_SubjectLine_AIWriter_Generated',
}

export enum GA4EventParam {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
}

export enum GA4EventParamName {
  SEARCH_TERM = 'searchTerm',
  TYPE = 'type',
  SLIDE_NUMBER = 'slideNumber',
  PLATFORM = 'platform',
  NUM_PLATFORMS = 'num_platforms',
  SIGNUP_PARAM_NAME = 'method',
  TERM = 'term',
  SEATS = 'seats',
  HASHED_ID = 'hashedId',
  USER_PREMIUM_LEVEL = 'userPremiumLevel',
  SOURCE = 'source',
}

export enum EditorMediaEvent {
  Audio_Fade_Add = 'Editor_Audio_Fade_Add',
  Audio_Fade_Removed = 'Editor_Audio_Fade_Removed',
  EDITOR_RECORDEDMEDIA_ADDED = 'Editor_RecordedMedia_Added',
  EDITOR_RECORDEDMEDIA_RETAKE = 'Editor_RecordedMedia_Retake',
}

export const trackPublishingGA4Events = (event: string, params?: Record<string, string>): void => {
  window.PMW.gtm.trackGA4CustomEvent(event, params);
};

export const trackPosterBuilderGA4Events = (event: string, params?: Record<string, string>): void => {
  window.PMW.gtm.trackGA4CustomEvent(event, params);
};
