export const PMW_STOCK_IMAGE_EXTENSION = 'png';

export const PMWStockImageSource = {
  PMW_ICON: 'pmw-icon',
  PMW_STILL_STICKER: 'pmw-still-sticker',
  PMW_EMOJI: 'pmw-emoji',
  PMW_STOCK_IMAGE: 'pmw-image',
};

export const PMWStockMediaSource = {
  PMW_ANIMATED_STICKER: 'pmw-animated-sticker',
  PMW_SHAPE: 'pmw-shape',
  ...PMWStockImageSource,
};

export type PMWStockMediaSourceType = (typeof PMWStockMediaSource)[keyof typeof PMWStockMediaSource];
export type PMWStockImageSourceType = (typeof PMWStockImageSource)[keyof typeof PMWStockImageSource];
