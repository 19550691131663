import type {ReactElement} from 'react';
import React from 'react';
import type {CHECKBOX_TYPE} from '@Components/checkbox';
import {Checkbox} from '@Components/checkbox';
import {Text, TextSize} from '@Components/text';
import {LabelledCheckboxSelectionTheme} from '@Components/labelled-checkbox/labelled-checkbox.types';
import {noop} from '@Utils/general.util';
import styles from './labelled-checkbox.module.scss';

export interface LabelledCheckboxProps {
  label: string;
  isChecked: boolean;
  className?: string;
  onChange?: (isChecked: boolean) => void;
  textClassName?: string;
  checkboxType?: CHECKBOX_TYPE;
  selectionTheme?: LabelledCheckboxSelectionTheme;
  hasHover?: boolean;
}

export function LabelledCheckbox({label, checkboxType, isChecked = false, className = '', onChange = noop, textClassName = '', selectionTheme = LabelledCheckboxSelectionTheme.NONE, hasHover = false}: LabelledCheckboxProps): ReactElement {

  const getClassesForSelectionTheme = (): string => {
    switch (selectionTheme) {
      case LabelledCheckboxSelectionTheme.PRIMARY:
        return 'bg-primary-10';

      default:
        return '';
    }
  }

  const getTextColorForSelectionTheme = (): string => {
    switch (selectionTheme) {
      case LabelledCheckboxSelectionTheme.PRIMARY:
        return 'content-primary';

      default:
        return '';
    }
  }
  
  const shouldTextBeBold = (): boolean => {
    return isChecked && selectionTheme !== LabelledCheckboxSelectionTheme.NONE;
  }

  const getContainerClasses = (): string => {
    const classes = [styles.checkboxRow, 'flex-row-align-center', 'hover-transition-all'];

    if (isChecked) {
      classes.push(`${getClassesForSelectionTheme()} ${styles.checked}`);
    }

    if (hasHover) {
      classes.push(styles.hoverBg);
    }

    return classes.join(' ');
  }

  return (
    <div className={`${getContainerClasses()} ${className}`} onClick={onChange.bind(null, !isChecked)}>
      <Checkbox isChecked={isChecked} type={checkboxType} />
      <Text val={label} size={TextSize.XSMALL} className={`spacing-m-l-2 ${isChecked ? getTextColorForSelectionTheme(): ''} ${textClassName}`} bold={shouldTextBeBold()} />
    </div>
  );
}
