export const MAX_SELECTION_NUMBER_OF_COLORS = 3;

// codeReviewShahrukh: clean this up and fix please
export enum BrandImageType {
  LOGO = 1,
  PRIMARY_LOGO = 2
}

export enum BrandColorType {
  PRIMARY = 1,
  SECONDARY,
  OTHER,
}

export enum BrandAssetType {
  BRAND_LOGOS = 1,
  BRAND_COLORS,
  BRAND_FONTS,
}


export enum BrandActivitySubjectType {
  SUBJECT_BRAND_LOGOS = 1,
  SUBJECT_BRAND_COLORS,
  SUBJECT_BRAND_FONTS,
  SUBJECT_BRAND_KIT,
}

export enum BrandActivityType {
  ADDED = 1,
  UPDATED,
  DELETED,
}
export interface Brand {
  brandId: string;
  idteam: string;
  name: string;
  creatorId: number;
  isDeleted: number;
  doesBrandHaveAllAssetTypes: boolean;
}

export interface ContentStateForSingleBrandKit {
  fonts: {
    loaded: number;
    ids: number[];
  };
  colors: {
    loaded: number;
    ids: number[];
  };
  logos: {
    loaded: number;
    ids: number[];
  };
}

export interface BrandContent {
  [brandId: string]: ContentStateForSingleBrandKit;
}

export interface BrandActivityMap {
  [brandId: string]: {
    loaded: number;
    ids: number[];
  };
}

export interface BrandColor {
  idbrandColor: number;
  brandId: string;
  hexCode: string;
  type: BrandColorType;
}

export interface BrandFont {
  idbrandFont: number,
  brandId: string,
  idfont: number,
  defaultColor: string,
  fontFamily: string,
  isInternalFont: boolean,
  brandFontName: string,
  isDeleted: number
}

export interface BrandLogo {
  id: number;
  filename: string;
  ext: string;
  src: string;
  hasTransparency: boolean;
  uploaderId: number;
  uploaderName: string;
  width: string;
  height: string;
  idbrandImage: number;
  brandId: string;
  brandImageType: BrandImageType;
}

export interface SelectableState {
  selectedColors: {
    ids: SelectableColorIds;
    defaultSelected: boolean;
  };
  selectedLogo: {
    id: number;
    defaultSelected: boolean;
  };
}

export enum BrandsLoadingState {
  NOT_LOADED = 1,
  LOADING = 2,
  LOADED = 3,
}

export interface SelectableColorIds {
  Primary: number;
  Secondary: number;
  Tertiary: number;
}

export interface SelectableColorHexes {
  Primary: string;
  Secondary: string;
  Tertiary: string;
}

export interface BrandsAppState {
  brands: Brand[];
  activeBrandId: ActiveBrandId;
  brandContent: BrandContent;
  brandActivityMap: BrandActivityMap;
  brandActivity: Record<string, unknown>; // Replace 'unknown' with the actual type
  brandActivityDetails: Record<string, unknown>; // Replace 'unknown' with the actual type
  brandsLoadingState: BrandsLoadingState;
  isUserTeamAdmin: boolean;
  showNewBrandMobileWizard: boolean;
  fonts: Record<string, unknown>; // Replace 'unknown' with the actual type
  colors: Record<string, BrandColor>;
  logos: Record<string, BrandLogo>;
  selectableState: SelectableState;
}

export interface BrandKitWithAssets {
  brandKit: Brand;
  colors: BrandColor[];
  fonts: BrandFont[];
  logos: BrandLogo[];
}

export type ActiveBrandId = string | null;

export const SELECTABLE_COLOR_EMPTY = -1;

export const EMPTY_SELECTABLE_COLORS_STATE = {
  Primary: SELECTABLE_COLOR_EMPTY,
  Secondary: SELECTABLE_COLOR_EMPTY,
  Tertiary: SELECTABLE_COLOR_EMPTY,
};

// codeReviewMoeed: replace with BrandColorType
export enum BRANDS_COLOR_CATEGORY {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  TERTIARY = 'Tertiary',
}

export interface RootStateWithBrandsRedux {
  brands: BrandsAppState
}