import {
    addBrandColor,
    addBrandFont,
    addNewBrand,
    changeBrandName, changePrimaryLogo,
    deleteBrand,
    deleteBrandAsset,
    getContentForBrandContentType,
    getBrandsAndActiveBrandsForUser,
    updateBrandAsset, changeActiveBrandId, getAllBrandsWithAssets,
} from "./brands-thunk.js";
import {
    BRAND_ASSETS,
    BRANDS_LOADING_STATES,
    getBrandPageContentTemplate,
    getBrandFromList,
    mapDataFromBackendToStateForBrandContentType,
    prepareBrandObject,
    setLoadingForBrandContentType,
    getBrandContentTemplate,
    setBrandContentTemplatesIfNotExist,
    BRAND_IMAGE_TYPES,
    setCorrectBrandIdForBrand,
    changeAllBrandImageTypesToLogoForBrand,
    BRAND_COLOR_TYPES,
    movePrimaryLogoToStart, setActiveBrandId, doesBrandHaveAllAssetTypes
} from "@Libraries/brands-library.js";

/**
 * Logic for extra reducers to attach to the brands redux slice goes in the extra reducers object
 * can be used for connecting different slices to brands slice or to handle createAsyncThunk lifecycle methods
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */
export const brandsExtraReducers = (builder) => {
    builder.addCase(getBrandsAndActiveBrandsForUser.pending, (state) => {
        setBrandsLoadingState(state, BRANDS_LOADING_STATES.LOADING);
    });

        builder.addCase(getBrandsAndActiveBrandsForUser.fulfilled, (state, {payload}) => {
        setBrandsAndActiveBrandsFromServerResponse(state, payload);
        setBrandsLoadingState(state, BRANDS_LOADING_STATES.LOADED);
    });

        builder.addCase(addNewBrand.pending, (state, {meta}) => {
        onNewBrandPendingFromServer(state, meta);
    });

        builder.addCase(addNewBrand.fulfilled, (state, {meta, payload}) => {
        onNewBrandSuccess(state, meta, payload);
    });

        builder.addCase(addNewBrand.rejected, (state, {meta}) => {
        onNewBrandFailure(state, meta);
    });

        builder.addCase(changeBrandName.fulfilled, (state, {meta}) => {
        const brand = getBrandFromList(meta.arg.brandId, state.brands);
        brand.name = meta.arg.newBrandName;
        resetActivityLogForBrand(state, meta.arg.brandId);
    });

        builder.addCase(getContentForBrandContentType.pending, (state, {meta}) => {
        setLoadingForBrandContentType(state, meta.arg.brandId, meta.arg.brandContentType);
    });

        builder.addCase(getContentForBrandContentType.fulfilled, (state, {meta, payload}) => {
        mapDataFromBackendToStateForBrandContentType(state, meta.arg.brandContentType, {brandId: meta.arg.brandId, payload: payload});
    });

        builder.addCase(addBrandFont.fulfilled, (state, {meta, payload}) => {
        const fontInfo = meta.arg;
        addBrandFontToState(state, fontInfo, payload.idbrandFont);
        resetActivityLogForBrand(state, fontInfo.brandId);
    });

        builder.addCase(addBrandColor.fulfilled, (state, {meta, payload}) => {
        onAddBrandColorSuccess(state, meta.arg, payload.idbrandColor);
        resetActivityLogForBrand(state, meta.arg.brandId);
    });

        builder.addCase(deleteBrand.pending, (state, {meta}) => {
        if (isNewActiveBrandIdGivenInRequest(meta)) {
            setActiveBrandId(state, meta.arg.newActiveBrandId);
            setBrandContentTemplatesIfNotExist(state, meta.arg.newActiveBrandId);
        }
    });

        builder.addCase(deleteBrand.rejected, (state, {meta}) => {
        setActiveBrandId(state, meta.arg.brandId);
    });

        builder.addCase(deleteBrand.fulfilled, (state, {meta}) => {
        onDeleteBrandSuccess(state, meta.arg.brandId, meta.arg.newActiveBrandId);
    });

        builder.addCase(deleteBrandAsset.pending, (state, {meta}) => {
        removeBrandAssetFromUI(state, meta.arg);
    });

        builder.addCase(deleteBrandAsset.fulfilled, (state, {meta}) => {
        removeBrandAssetFromStore(state, meta.arg);
        refreshBrandAssetsLoadedStatusForBrand(state, meta.arg.brandId);
        resetActivityLogForBrand(state, meta.arg.brandId);
    });

        builder.addCase(updateBrandAsset.pending, (state, {meta}) => {
        const {brandAssetType, brandAssetParams} = meta.arg;
        updateBrandAssetDetailsInStore(state, brandAssetType, brandAssetParams);
    });

        builder.addCase(updateBrandAsset.fulfilled, (state, {meta}) => {
        const {brandAssetParams} = meta.arg;
        onBrandAssetUpdateSuccess(state, meta.arg.brandAssetType, brandAssetParams);
        resetActivityLogForBrand(state, brandAssetParams.brandId);
    });

        builder.addCase(changeActiveBrandId.pending, (state, {meta}) => {
        setActiveBrandId(state, meta.arg.newActiveBrandId);
        setBrandContentTemplatesIfNotExist(state, meta.arg.newActiveBrandId);
    });

        builder.addCase(changePrimaryLogo.pending, (state, {meta}) => {
        const {idbrandLogo, brandId} = meta.arg;
        changePrimaryLogoInStore(state, idbrandLogo, brandId);
    });

        builder.addCase(changePrimaryLogo.fulfilled, (state, {meta}) => {
        resetActivityLogForBrand(state, meta.arg.brandId);
    });

    builder.addCase(getAllBrandsWithAssets.pending, (state) => {
        setBrandsLoadingState(state, BRANDS_LOADING_STATES.LOADING);
    });

    builder.addCase(getAllBrandsWithAssets.rejected, (state) => {
        setBrandsLoadingState(state, BRANDS_LOADING_STATES.LOADED);
    });

    builder.addCase(getAllBrandsWithAssets.fulfilled, (state, {payload}) => {
        setBrandsAndActiveBrandsFromServerResponse(state, payload);
        setBrandContentStateForAllBrandsFromServer(state, payload);
        setBrandsLoadingState(state, BRANDS_LOADING_STATES.LOADED);
    });
}

/**
 *
 * @param state
 * @param {string} brandId
 */
export const resetActivityLogForBrand = (state, brandId) => {
    state.brandActivityMap[brandId] = getBrandContentTemplate();
}


/**
 * changes the logo for which ID is given to a primary brand logo
 * and changes the rest of the logos for that brand to normal logo
 * @param state
 * @param {number} idbrandLogo the ID of the logo to make primary
 * @param {string} brandId The ID of the brand that contains the logo
 */
const changePrimaryLogoInStore = (state, idbrandLogo, brandId) => {
    state.logos[idbrandLogo].brandImageType = BRAND_IMAGE_TYPES.TYPE_BRAND_LOGO_PRIMARY;
    changeAllBrandImageTypesToLogoForBrand(state.logos, state.brandContent[brandId].logos.ids, idbrandLogo);
    movePrimaryLogoToStart(state, brandId);
}


/**
 * Replace a brand asset in store with the new object being provided
 * @param state
 * @param {number} brandAssetType
 * @param {Object} brandAssetParams
 */
const updateBrandAssetDetailsInStore = (state, brandAssetType, brandAssetParams) => {
    if (brandAssetType === BRAND_ASSETS.BRAND_COLORS) {
        setBrandColorType(state, brandAssetParams.currentBrandColorId, BRAND_COLOR_TYPES.OTHER);
        state.colors[brandAssetParams.idbrandColor] = brandAssetParams;
        sortBrandColorIdsByTypeForBrand(state, brandAssetParams.brandId);
    }
}

/**
 * @param state the brands redux slice
 * @param {number} brandAssetType the type of brand asset (font or color). one of BRAND_ASSETS.* constants
 * @param {Object} brandAssetParams
 */
const onBrandAssetUpdateSuccess = (state, brandAssetType, brandAssetParams) => {
    switch (brandAssetType) {
        case BRAND_ASSETS.BRAND_FONTS:
            state.fonts[brandAssetParams.idbrandFont] = brandAssetParams;
            break;
    }
}

/**
 * deletes the brand from store and if no new active brand ID is given, reset the active brands in store
 * @param state
 * @param {string} brandId
 * @param {string|null|undefined} newActiveBrandId
 */
const onDeleteBrandSuccess = (state, brandId, newActiveBrandId) => {
    state.brands = state.brands.filter(brand => brand.brandId !== brandId);
    state.activeBrandId = newActiveBrandId;
}

/**
 *
 * @param state
 * @param {Object} [requestInfo] data that was sent into AJAX request
 * @param {number} [requestInfo.brandAssetType] the asset type. one of BRAND_ASSETS.* constants
 * @param {string} [requestInfo.brandId] the brand the asset was linked with
 * @param {number} [requestInfo.idbrandAsset] the ID of the asset that was deleted
 */
const removeBrandAssetFromUI = (state, requestInfo) => {
    const {brandAssetType, brandId, idbrandAsset} = requestInfo;
    switch (brandAssetType) {
        case BRAND_ASSETS.BRAND_LOGOS:
            state.brandContent[brandId].logos.ids =  state.brandContent[brandId].logos.ids.filter(id => id !== idbrandAsset);
            break;
        case BRAND_ASSETS.BRAND_COLORS:
            state.brandContent[brandId].colors.ids =  state.brandContent[brandId].colors.ids.filter(id => id !== idbrandAsset);
            break;

        case BRAND_ASSETS.BRAND_FONTS:
            state.brandContent[brandId].fonts.ids =  state.brandContent[brandId].fonts.ids.filter(id => id !== idbrandAsset);
            break;
    }
}

/**
 *
 * @param state
 * @param {Object} [requestInfo] data that was sent into AJAX request
 * @param {number} [requestInfo.brandAssetType] the asset type. one of BRAND_ASSETS.* constants
 * @param {string} [requestInfo.brandId] the brand the asset was linked with
 * @param {number} [requestInfo.idbrandAsset] the ID of the asset that was deleted
 */
const removeBrandAssetFromStore = (state, requestInfo) => {
    const {brandAssetType, idbrandAsset} = requestInfo;
    switch (brandAssetType) {
        case BRAND_ASSETS.BRAND_LOGOS:
            delete state.logos[idbrandAsset];
            break;
        case BRAND_ASSETS.BRAND_COLORS:
            delete state.colors[idbrandAsset];
            break;

        case BRAND_ASSETS.BRAND_FONTS:
            delete state.fonts[idbrandAsset];
            break;
    }
}

/**
 *
 * @param state
 * @param {string} brandId
 */
const refreshBrandAssetsLoadedStatusForBrand = (state, brandId) => {
    getBrandFromList(brandId, state.brands).doesBrandHaveAllAssetTypes = doesBrandHaveAllAssetTypes(brandId, state);

}

/**
 *
 * @param state
 * @param fontInfo
 * @param {number} idbrandFont
 */
const addBrandFontToState = (state, fontInfo, idbrandFont) => {
    state.brandContent[fontInfo.brandId].fonts.ids.push(idbrandFont);
    state.fonts[idbrandFont] = {...fontInfo, idbrandFont: idbrandFont};
}

/**
 * adds brand color to state
 * @param state
 * @param {Object} [colorInfo]
 * @param {string} [colorInfo.hexCode]
 * @param {string} [colorInfo.brandId]
 * @param {number} [colorInfo.type]
 * @param {number} [colorInfo.currentBrandColorId]
 * @param {number} idbrandColor
 */
const onAddBrandColorSuccess = (state, colorInfo, idbrandColor) => {
    state.brandContent[colorInfo.brandId].colors.ids.push(idbrandColor);
    state.colors[idbrandColor] = {...colorInfo, idbrandColor: idbrandColor};
    setBrandColorType(state, colorInfo.currentBrandColorId, BRAND_COLOR_TYPES.OTHER);
    sortBrandColorIdsByTypeForBrand(state, colorInfo.brandId);
}

/**
 *
 * @param state
 * @param {string} brandId
 */
const sortBrandColorIdsByTypeForBrand = (state, brandId) => {
    state.brandContent[brandId].colors.ids.sort((id1, id2) => {
        return state.colors[id1].type - state.colors[id2].type;
    });
}

/**
 * when the AJAX request is pending from the server, set the ajaxSent flag to true if a brand was created with a temporary brand ID (for UI purposes)
 * @param state
 * @param {Object} requestMetadata
 */
const onNewBrandPendingFromServer = (state, requestMetadata) => {
    state.isNewBrandBeingCreated = true;
    if (requestMetadata.arg.temporaryBrandId) {
        let brand = getBrandFromList(requestMetadata.arg.temporaryBrandId, state.brands);
        brand.name = requestMetadata.arg.brandName;
        brand.ajaxSent = true;
    }
}

/**
 * on successfully adding a new brand to the DB, update the redux store for brands
 * if an active context was provided (mystuff or editor), then a new brand object is initialized and added to the store,
 * and the new brand will be set as the active brand in the provided context
 * if there was no active context, then the brand object was already initialized, and the brandId needs to be replaced only
 * The
 * @param state the redux store
 * @param {Object} meta the metadeta for the current addBrand ajax request
 * @param {Object} payload the response from the server
 */
const onNewBrandSuccess = (state, meta, payload) => {
    if (!meta.arg.temporaryBrandId) {
        state.brands.push(prepareBrandObject(payload.brandId, meta.arg.brandName, PMW.getUserId()));
    } else {
        setCorrectBrandIdForBrand(state, meta.arg.temporaryBrandId, payload.brandId);
    }

    setActiveBrandId(state, payload.brandId);
    setBrandContentTemplatesIfNotExist(state, payload.brandId);

    if (PMW.isMobileScreenWidth()) {
        state.showNewBrandMobileWizard = true;
    }
    state.isNewBrandBeingCreated = false;
}

/**
 * failure handler for new brand creation flow
 * if a brand was created with a temporary ID and the request did not return a success response,
 * then remove this brand from the store to accurately represent the state of the UI
 * @param state
 * @param {Object} meta the request metadata passed to redux thunk
 */
const onNewBrandFailure = (state, meta) => {
    if (meta.arg.temporaryBrandId) {
        state.brands = state.brands.filter(brand => brand.brandId !== meta.arg.temporaryBrandId);
    }
    state.isNewBrandBeingCreated = false;
}
/**
 *
 * @param {Object} requestMetadata
 * @returns {boolean}
 */
export const isActiveBrandContextGivenInRequest = (requestMetadata) => {
    return requestMetadata.arg.activeContext && requestMetadata.arg.activeContext !== '';
}

/**
 * Sets the brand color's type according to the param passed
 * @param state
 * @param {number|undefined|null} idbrandColor the id of the color to modify
 * @param {number} colorType one of BRAND_COLOR_TYPES.* constants
 */
const setBrandColorType = (state, idbrandColor, colorType) => {
    if (idbrandColor) {
        state.colors[idbrandColor].type = colorType;
    }
}

/**
 *
 * @param {Object} requestMetadata
 * @returns {boolean}
 */
const isNewActiveBrandIdGivenInRequest = (requestMetadata) => {
    return requestMetadata.arg.newActiveBrandId && requestMetadata.arg.newActiveBrandId !== '';
}

/**
 *
 * @param state
 * @param {number} loadingState one of BRANDS_LOADING_STATES.* constants
 */
const setBrandsLoadingState = (state, loadingState) => {
    state.brandsLoadingState = loadingState;
}

/**
 *
 * @param state
 * @param {Object} [serverResponse]
 * @param {Array} [serverResponse.brands]
 * @param {Object} [serverResponse.activeBrands]
 * @param {boolean} [serverResponse.isUserTeamAdmin]
 * @param {string|null} [serverResponse.activeBrandId]
 */
const setBrandsAndActiveBrandsFromServerResponse = (state, serverResponse) => {
    state.brands = serverResponse.brands;
    if (serverResponse.brands.length > 0) {
        state.activeBrandId = serverResponse.activeBrandId;
        state.brandContent[serverResponse.activeBrandId] = getBrandPageContentTemplate();
        state.brandActivityMap[serverResponse.activeBrandId] = getBrandContentTemplate();
    }

    state.isUserTeamAdmin = serverResponse.isUserTeamAdmin;
}

/**
 *
 * @param state
 * @param {Object} [serverResponse]
 * @param {Array} [serverResponse.brands]
 * @param {Array} [serverResponse.allLogosInUserBrands]
 * @param {Array} [serverResponse.allColorsInUserBrands]
 * @param {Array} [serverResponse.allFontsInUserBrands]
 */
const setBrandContentStateForAllBrandsFromServer = (state, serverResponse) => {
    state.brands.forEach(brand => {
        setLogosInBrandContentUsingAllLogosFromResponse(state, brand, serverResponse.allLogosInUserBrands);
        setColorsInBrandContentUsingAllLogosFromResponse(state, brand, serverResponse.allColorsInUserBrands);
        setFontsInBrandContentUsingAllLogosFromResponse(state, brand, serverResponse.allFontsInUserBrands);
    });
}

/**
 *
 * @param state
 * @param {Object} brand
 * @param {Array} allLogosInUserBrands
 */
const setLogosInBrandContentUsingAllLogosFromResponse = (state, brand, allLogosInUserBrands) => {
    const logosInBrand = allLogosInUserBrands.filter(logo => logo.brandId === brand.brandId);
    setBrandContentTemplatesIfNotExist(state, brand.brandId);
    mapDataFromBackendToStateForBrandContentType(state, BRAND_ASSETS.BRAND_LOGOS, {brandId: brand.brandId, payload: logosInBrand});
}

/**
 *
 * @param state
 * @param {Object} brand
 * @param {Array} allColorsInUserBrands
 */
const setColorsInBrandContentUsingAllLogosFromResponse = (state, brand, allColorsInUserBrands) => {
    const colorsInBrand = allColorsInUserBrands.filter(color => color.brandId === brand.brandId);
    setBrandContentTemplatesIfNotExist(state, brand.brandId);
    mapDataFromBackendToStateForBrandContentType(state, BRAND_ASSETS.BRAND_COLORS, {brandId: brand.brandId, payload: colorsInBrand});
}

/**
 *
 * @param state
 * @param {Object} brand
 * @param {Array} allFontsInUserBrands
 */
const setFontsInBrandContentUsingAllLogosFromResponse = (state, brand, allFontsInUserBrands) => {
    const fontsInBrand = allFontsInUserBrands.filter(font => font.brandId === brand.brandId);
    setBrandContentTemplatesIfNotExist(state, brand.brandId);
    mapDataFromBackendToStateForBrandContentType(state, BRAND_ASSETS.BRAND_FONTS, {brandId: brand.brandId, payload: fontsInBrand});
}